import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import "swiper/css/swiper.css"
import useMediaQuery from "../../hooks/useMediaQuery"

const ItConsulting = () => {
  const isDesktop = useMediaQuery("(min-width: 768px)")

  return (
    <section
      id="it-consulting"
      className="it-consulting"
      style={{ padding: "1.5rem 0" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-8 mx-auto text-center">
            <h2 className="my-3 font-weight-medium">
              Quickbase Custom Application Development: On Time and On Budget
            </h2>
            <p className="mx-auto" style={{ maxWidth: "825px" }}>
              Bring your team’s ideas to life with custom Quickbase
              solutions—built without overloading your IT team or exceeding your
              budget.
            </p>
          </div>
        </div>
        {isDesktop ? (
          <LazyLoadComponent>
            <div class="tabs pt-4 custom-quickbase">
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab1"
                defaultChecked
              />
              <label
                for="tab1"
                class="tabs__label mr-3 ml-auto mt-2 text-center"
              >
                Tools for Any Task
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/de70ea45d643af8bb3d37b94ef38da03c83cb935-1280x853.jpg"
                      alt="Tools for Any Task"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Tools for Any Task</h3>
                    <p className="my-4 text-left-mobile">
                      From simple automations to enterprise-grade, multinational
                      procurement systems, we build custom Quickbase
                      applications that fit your needs.
                    </p>
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab2"
              />
              <label
                for="tab2"
                class="tabs__label ml-3 mr-auto text-center mt-2"
              >
                Scalable Applications
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-5 text-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Scalable Applications</h3>
                    <p className="my-4 text-left-mobile">
                      Improve your team’s output without increasing
                      costs—automate processes, reduce errors, eliminate waste,
                      and innovate.
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 my-lg-4 my-md-4 my-0 mt-lg-0 mt-md-0 mt-5">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/87791e71e0713bb25e3b1500c3000b11b4ddc83a-4288x2848.jpg"
                      alt="Scalable Applications"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab3"
              />
              <label
                for="tab3"
                class="tabs__label mr-3 ml-auto mt-2 text-center"
              >
                Low Code, High Efficiency
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/01a4899168e9d0b6b4c18865b727a4b4869e0d93-1280x853.jpg"
                      alt=" Low Code, High Efficiency"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">
                      Low Code, High Efficiency
                    </h3>
                    <p className="my-4 text-left-mobile">
                      Use Quickbase to design and deploy robust applications in
                      weeks—no bloated development processes, code, or costs.
                    </p>
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab4"
              />
              <label
                for="tab4"
                class="tabs__label ml-3 mr-auto text-center mt-2"
              >
                Unique Solutions for Unique Challenges
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/f8356a7ddf1a900c4acf2893995a37699db5af20-4000x2667.jpg"
                      alt=" Unique Solutions for Unique Challenges"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">
                      Unique Solutions for Unique Challenges
                    </h3>
                    <p className="my-4 text-left-mobile">
                      Don’t force your business into rigid systems. Fit your
                      systems into your business with custom applications.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </LazyLoadComponent>
        ) : (
          <div className="mobile-swiper d-flex flex-column pt-2">
            <LazyLoadComponent>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Tools for Any Task</h3>
                    <p className="my-4 ">
                      From simple automations to enterprise-grade, multinational
                      procurement systems, we build custom Quickbase
                      applications that fit your needs.
                    </p>
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100 img-fluid"
                      src="https://cdn.sanity.io/images/55lcecww/production/de70ea45d643af8bb3d37b94ef38da03c83cb935-1280x853.jpg"
                      alt="Tools for Any Task"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Scalable Applications</h3>
                    <p className="my-4 ">
                      Improve your team’s output without increasing
                      costs—automate processes, reduce errors, eliminate waste,
                      and innovate.
                    </p>
                  </div>
                  <div className="col-12">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/87791e71e0713bb25e3b1500c3000b11b4ddc83a-4288x2848.jpg"
                      alt="Scalable Applications"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Low Code, High Efficiency</h3>
                    <p className="my-4 ">
                      Use Quickbase to design and deploy robust applications in
                      weeks—no bloated development processes, code, or costs.
                    </p>
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/01a4899168e9d0b6b4c18865b727a4b4869e0d93-1280x853.jpg"
                      alt=" Low Code, High Efficiency"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Unique Solutions for Unique Challenges</h3>
                    <p className="my-4 ">
                      Don’t force your business into rigid systems. Fit your
                      systems into your business with custom applications.
                    </p>
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/f8356a7ddf1a900c4acf2893995a37699db5af20-4000x2667.jpg"
                      alt=" Unique Solutions for Unique Challenges"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
            </LazyLoadComponent>
          </div>
        )}
      </div>
    </section>
  )
}

export default ItConsulting
