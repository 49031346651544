import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { FaHandshake, FaPeopleCarry } from "react-icons/fa"
import { GiFreedomDove } from "react-icons/gi"
import { HiLightBulb } from "react-icons/hi"
import { LazyLoadComponent } from "react-lazy-load-image-component"

import Layout from "../components/layout.js"
import Seo from "../components/seo.js"
// import PartnerSwiper from "../components/swiper/swiper-partner.js"
// import Testimonials from "../components/swiper/testimonial-swiper"
// import Cases from "../components/cases"
import Banner from "../components/hero/quandary-banner.js"
import TeamBanner from "../components/hero/banner-hometeam.js"

import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

import PartnerSwiper from "../components/swiper/swiper-partner.js"
import Testimonials2 from "../components/swiper/testimonial-swiper.js"
import ItConsulting from "../components/multi-tab/quandary-build-multi-tab.js"
import Testimonials from "../components/swiper/testimonial-swiper.js"
import CaseStudySection from "../components/header/header-casestudy-section.js"

import UnlockEnterprise from "../components/unlock-enterprise.js"

import Cases from "../components/cases.js"

class QuandaryBuildPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} banner={<Banner />}>
        <Seo
          title="Quickbase Application Development | Quandary Consulting Group"
          description="We provide ongoing Quickbase development and support for businesses looking to build custom solutions that enhance operations across teams."
        />

        <div className="page-content pt-0 pb-0">
          <PartnerSwiper />
          <ItConsulting />
          <Testimonials />
          <CaseStudySection />
          <CaseComponent dataSrc={this.props.data.allSanityCaseStudy} />
          <TeamBanner />
          <UnlockEnterprise />
          <section
            className="bg-case-study slogan-section"
            style={{
              paddingTop: "5rem",
              paddingBottom: "5rem",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-10 mx-auto text-center">
                  <div>
                    <h2 className="text-white">
                      From Idea to Application to Results in Weeks With Rapid
                      Application Development Using Quickbase
                    </h2>
                    {/* <p className="text-white">
                  Our 10-point security audit will show you how secure your
                  applications are. <br /> Protect your data. Protect your
                  investment. Protect your organization.
                </p> */}

                    <Link
                      to="/contact/"
                      className="btn btn-primary btn-arrow mr-2 mt-4 text-center py-3 text-uppercase"
                      style={{ marginTop: "auto" }}
                    >
                      Let's Chat
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default QuandaryBuildPage

const CaseComponent = ({ dataSrc }) => {
  return (
    <section id="case-studies-container">
      <div id="case-studies" className="bg-case-study py-5">
        <div className="container d-none">
          <div className="intro text-center">
            <div className="row">
              <div className="col-md-10 mx-auto">
                {/* <p
                  className="h5 text-light-blue text-uppercase font-weight-bold m-0"
                  style={{ fontFamily: "Futura,sans-serif" }}
                >
                  Case Studies
                </p>
                <h2 className=" my-2 text-white">
                  We Save Clients 1000s of Hours. Every Year.
                </h2> */}
                {/* <p className="text-white">
                  We helped a national healthcare provider save over 9,000 hours
                  in weekly labor costs by developing a single application...
                </p> */}
              </div>
            </div>
          </div>
        </div>
        {dataSrc && <Cases allSanityCaseStudy={dataSrc} />}
        <div className="text-center">
          <Link
            className="btn btn-primary btn-arrow mr-2 mt-4 text-center"
            to="/case-studies/"
          >
            More Case Studies
          </Link>
        </div>
      </div>
    </section>
  )
}

export const quandaryBuildPageQuery = graphql`
  query quandaryBuildPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
